export const socialsData = [
  {
    social: "LinkedIn",
    url: "https://www.linkedin.com/in/weronikaszpejewska/",
    active: true,
  },
  {
    social: "GitHub",
    url: "https://github.com/weradevelops",
    active: true,
  },
];

import { useRef } from "react";

function Hamburger() {
  const toggleRef = useRef();
  const menuRef = useRef();

  const handleHamburgerMenu = (event) => {
    event.preventDefault();
    toggleRef.current.classList.toggle("active");
    menuRef.current.classList.toggle("open");
  };

  return (
    <>
      <div
        className="hamburger"
        onClick={handleHamburgerMenu}
        ref={toggleRef}
      ></div>
      <div className="offcanvas-menu" ref={menuRef}>
        <ul>
          <li>
            <a href="#home">Home</a>
          </li>
          <li>
            <a href="#work">Work</a>
          </li>
          {/* <li>
            <a href="#about">About</a>
          </li> */}
          <li>
            <a href="#contact">Contact</a>
          </li>
          <li>
            <a
              href="/Weronika_Szpejewska_Frontend_Developer_CV.pdf"
              download="Weronika_Szpejewska_CV"
            >
              Resume
            </a>
          </li>
        </ul>
      </div>
    </>
  );
}

export default Hamburger;

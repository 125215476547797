function Socials({ social, url }) {
  return (
    <li className="item">
      <a href={url} target="_blank" rel="noopener noreferrer">
        {social}
      </a>
    </li>
  );
}

export default Socials;

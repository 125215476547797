import ProjectDetails from "./ProjectDetails";

function Projects() {
  return (
    <div id="work" className="projects">
      {/*<h3 className="title">work_</h3>*/}
      <div className="project-block">
        <ProjectDetails />
      </div>
    </div>
  );
}

export default Projects;

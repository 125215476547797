function ProjectCard({ thumbnail, url, heading, description }) {
  return (
    <div className="item">
      <Thumbnail thumbnail={thumbnail} url={url} heading={heading} />
      <Heading heading={heading} />
      <Description description={description} />
    </div>
  );
}

function Thumbnail({ thumbnail, url, heading }) {
  return (
    <div className="thumbnail">
      <a href={url} target="_blank" rel="noopener noreferrer">
        <img src={thumbnail} alt={`Preview of ${heading}`} />
      </a>
    </div>
  );
}

function Heading({ heading }) {
  return (
    <div className="heading">
      <h3>{heading}</h3>
    </div>
  );
}

function Description({ description }) {
  return (
    <div className="description">
      <p>{description}</p>
    </div>
  );
}

export default ProjectCard;

import Hero from "./components/Hero";
/*import About from "./components/About";*/
import Projects from "./components/Projects";
import Footer from "./components/Footer";

function App() {
  return (
    <div className="App">
      <Hero />
      <Projects />
      {/*<About />*/}
      <Footer />
    </div>
  );
}

export default App;

/*function Slider() {
  return (
    <div className="slider">
      <ul className="skill-list">
        {skillsData.map((s, index) => (
          <Skill key={index} skill={s.skill} level={s.level} />
        ))}
      </ul>
    </div>
  );
}
function Skill({ skill, level }) {
  return (
    <li className="skill">
      <span>{skill}</span>
      <span>
        {level === "beginner" && ""}
        {level === "intermediate" && ""}
        {level === "advanced" && ""}
      </span>
    </li>
  );
}*/

import { socialsData } from "../data/socialsData";
import Socials from "./Socials";

function Footer() {
  const handleContact = () => {
    window.location.href = "mailto:weronika.szpeje@gmail.com";
  };

  return (
    <footer id="contact" className="footer">
      <div className="footer-block">
        <div className="contact">
          <p>👋Get in touch at</p>
          <h4>weronika.szpe@gmail.com</h4>
          <button className="contact-btn" onClick={handleContact}>
            <span>Contact Me</span>
          </button>
        </div>
        <div className="socials">
          <ul className="links">
            {socialsData
              .filter((s) => s.active)
              .map((s, index) => (
                <Socials key={index} social={s.social} url={s.url} />
              ))}
          </ul>
        </div>
      </div>
    </footer>
  );
}

export default Footer;

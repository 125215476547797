function Navigation({ link, url, download }) {
  return (
    <li className="item">
      <a href={url} download={download ? "Weronika_Szpejewska_CV" : undefined}>
        {link}
      </a>
    </li>
  );
}

export default Navigation;

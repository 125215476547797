export const projectData = [
  {
    heading: "European Fine Food & Deli",
    description:
      "A static informative website for a local client specializing in European imported food and fresh deli meats and cheeses.",
    thumbnail: "assets/img/european-deli.png",
    active: true,
    url: "https://europeanfinefooddeli.com/",
  },
  {
    heading: "Bs Virk Llc.",
    description:
      "A dynamic website that accommodated account creation, profile adjustments, parking lot requests, and direct messaging.",
    thumbnail: "assets/img/bs-virk-llc.png",
    active: true,
    url: "https://www.trucksspot.com/",
  },
  {
    heading: "Fast React Pizza Co.",
    description:
      "A React homepage displaying Pizza menus and details of the restaurants hours.",
    thumbnail: "assets/img/fast-react-pizza.png",
    active: true,
    url: "https://github.com/weradevelops/pizza-menu",
  },
];

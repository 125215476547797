import { ReactTyped } from "react-typed";
import Hamburger from "./Hamburger";
import Navigation from "./Navigation";
import { navigationData } from "../data/navigationData";

function Hero() {
  const underscore = '<span style="color: #283832; font-weight:600;">.</span>';

  return (
    <>
      <a className="offscreen skip-to-content" href="#content">
        Skip to main content
      </a>
      <hr id="home" />
      <div className="nav-bar">
        <div className="logo">
          <a href="#home">
            <p>wera.</p>
          </a>
        </div>
        <div className="links">
          <ul className="list">
            {navigationData
              .filter((n) => n.active)
              .map((n, index) => (
                <Navigation
                  key={index}
                  link={n.link}
                  url={`${n.url}`}
                  download={n.download}
                />
              ))}
          </ul>
        </div>
        <Hamburger />
      </div>
      <div id="content" className="main">
        <div className="main-block">
          <div className="auto-type-block">
            <div className="auto-type">
              <ReactTyped
                strings={[
                  `Hello! I'm Wera${underscore}`,
                  `Salve! Sono Wera${underscore}`,
                  `Witaj! Jestem Wera${underscore}`,
                ]}
                typeSpeed={100}
                backSpeed={60}
                atr="placeholder"
                loop
              ></ReactTyped>
            </div>
          </div>
          <div className="intro-block">
            <h2 className="whoiam">
              <span>
                An ambitious React Front-End Developer creating responsive
                websites and mobile apps in my free time. I'm eager to find a
                company where I can{" "}
                <span className="whoiam-highlight">commit -all</span> my skills
                and creativity.
              </span>
            </h2>
          </div>
        </div>
      </div>
    </>
  );
}

export default Hero;

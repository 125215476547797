export const navigationData = [
  {
    link: "work",
    active: true,
    url: "#work",
    download: false,
  },
  {
    link: "about",
    active: false,
    url: "#about",
    download: false,
  },
  {
    link: "contact",
    active: true,
    url: "#contact",
    download: false,
  },
  {
    link: "resume",
    active: true,
    url: "/Weronika_Szpejewska_Frontend_Developer_CV.pdf",
    download: true,
  },
];

import { projectData } from "../data/projectData";
import ProjectCard from "./ProjectCard";

function ProjectDetails() {
  return (
    <div className="details">
      {projectData.map((project, index) => (
        <ProjectCard
          key={index}
          thumbnail={project.thumbnail}
          url={project.url}
          heading={project.heading}
          description={project.description}
        />
      ))}
    </div>
  );
}

export default ProjectDetails;
